<template>
  <div>
    <v-simple-table>
      <thead v-if="exercises.length">
        <tr>
          <th>Title</th>
          <th>Exercise Notes</th>
          <th>Exercise Seed Tracker</th>
        </tr>
      </thead>
      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
      >
        <tr
          class="clickable new-tab-link-row"
          v-for="exercise in exercises"
          :key="exercise.id"
        >
          <td v-list-link="setListLink(exercise.id)">
            {{ exercise.title }}
          </td>
          <td v-list-link="setListLink(exercise.id)" width="30%">
            {{ exercise.description }}
          </td>

          <td v-list-link="setListLink(exercise.id)" width="30%">
            {{ exercise.seed_tracker }}
          </td>
        </tr>
      </tbody>

      <table-skeleton-loader v-if="loading" column="2" row />
    </v-simple-table>

    <no-list
      v-if="!exercises.length && !loading"
      details="No Available Exercises"
    />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'

import { mdiDotsVertical } from '@mdi/js'
import { includes } from 'lodash'

export default {
  name: 'ExercisesListTable',

  directives: {
    infiniteScroll,
  },

  components: {
    TableSkeletonLoader,
    NoList,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    exercises: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons: {
        action: mdiDotsVertical,
      },
    }
  },

  computed: {
    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },

    redirectPathName() {
      return includes(this.$route.name, 'active')
        ? 'exercise.details'
        : 'new.exercise'
    },
  },

  methods: {
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },

    setListLink(exerciseId) {
      return {
        id: exerciseId,
        routeName: this.redirectPathName,
        fullPath: `/exercises/${exerciseId}/details`,
        meta: { stage: 1 },
      }
    },

    showDetails(exercise) {
      this.$router.push({
        name: exercise.completed_at ? 'exercise' : 'new.exercise',
        params: { id: exercise.id },
      })
    },
  },
}
</script>
